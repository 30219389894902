<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <div style="display: flex; justify-content: space-between">
                  <h6 class="mb-0">DANH SÁCH CHIẾN DỊCH THIỆN NGUYỆN</h6>
                  <router-link :to="'/charity/campaign-charities/create'">
                    <b-button class="btn btn-light font-weight-bold btn-sm"
                    >Tạo mới
                    </b-button>
                  </router-link>
                </div>
              </template>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fields"
              >
                <template #cell(image)="data">
                  <div>
                    <img width="50" :src="data.item.image" >
                  </div>
                </template>
                <template #cell(name)="data">
                  <div >
                    <div>
                      {{data.item.name}}
                    </div>
                  </div>

                </template>

                <template #cell(active)="data">
                  <div class="label label-inline label-light-success" v-if="data.item.active == 1">
                    Có
                  </div>
                  <div class="label label-inline label-light-danger" v-else>
                    Không
                  </div>
                </template>
                <template #cell(position)="data">

                  <div >
                    {{data.item.detail.position}}
                  </div>
                </template>
                <template #cell(date_start)="data">

                  <div >
                    {{data.item.detail.date_start}}
                  </div>
                </template>
                <template #cell(date_end)="data">

                  <div >
                    {{data.item.detail.date_end}}
                  </div>
                </template>
                <template #cell(amount)="data">

                  <div >
                    {{numberFormat(data.item.orders_sum) +'đ/' +numberFormat(data.item.detail.target_money)+'đ' }}
                  </div>
                </template>
                <template #cell(num)="data">

                  <div >
                    {{data.item.orders_count}}
                  </div>
                </template>
                <template #cell(process_label)="data">

                  <div class="label label-inline " :class="getLabelProcess(data.item,true)">
                    {{getLabelProcess(data.item)}}
                  </div>
                </template>


                <template #cell(action)="data">
                  <div style="display: flex">
                    <router-link
                        :to="'/charity/campaign-charities/edit/' + data.item.id"
                    >
                      <b-button style="margin-right: 10px;" pill variant="info" size="sm">Sửa </b-button>
                    </router-link>

                    <b-button
                        @click="destroy(data.item.id)"
                        pill
                        variant="danger"
                        size="sm"
                    >Xóa
                    </b-button>
                  </div>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.limit"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import moment from "moment";

const BackendRepository = RepositoryFactory.get("Backend");

export default {
  name: "List.vue",
  mixins: [Common],
  components:{
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "STT",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "name",
          label: "Tên chiến dịch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "position",
          label: "Vị trí",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date_start",
          label: "Bắt đầu",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },

        {
          key: "date_end",
          label: "Kết Thúc",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },/*
        {
          key: "user",
          label: "User",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },*/
        {
          key: "num",
          label: "Số Lượt",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "amount",
          label: "Số Tiền",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },

        {
          key: "active",
          label: "Hiển thị",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },

        {
          key: "process_label",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },

        {
          key: "action",
          label: "Hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 10,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      clients: [],
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý tổ chức", route: "/charity/campaign-charities" },
    ]);
    this.filter = this.$route.query;
  },
  methods: {
    getLabelProcess(item,isClass = false){
      let txtClass = 'label-light-info';
      let txt = 'Chưa diễn ra';
      let target_money = parseFloat(item.detail.target_money)
      let sum =  parseFloat(item.orders_sum);
      let date_end = Date.parse(item.detail.date_end)
      let date_start = Date.parse(item.detail.date_start)

      if (date_start < Date.now()  ){
        if (target_money === sum){
          txtClass =  'label-light-success'
          txt =  'Đạt mục tiêu'
        }else if ( sum > target_money){
          txtClass =  'label-light-success'
          txt =  'Vượt mục tiêu'
        }else if ( sum <  target_money && date_end <  Date.now() ){
          txtClass =  'label-light-danger'
          txt =  'Không đạt mục tiêu'
        }else {
          txtClass =  'label-light-warning'
          txt =  'Đang diễn ra'
        }
      }

      if (isClass){
        return txtClass;
      }else {
        return txt;
      }
    },
    numberFormat(value) {

      if (typeof value != "number" ) {
        return 0;
      }
      var number = parseInt(value);
      if (isNaN(number) || number == null) {
        return 0;
      }
      var formatter = new Intl.NumberFormat("it-IT");
      return formatter.format(number);
    },
    getType(items){
      let array_type=[];
      let that = this;
      let label = '';
      items.forEach((item) => {
        if (!array_type.includes(item.type)){
          array_type.push(item.type)
        }
      })
      array_type.forEach((item)=>{
        that.arrayFilter.forEach((type)=>{
          if (item == type.status){
            label += type.label+ ', ';
          }
        })
      })
      return label.replace(/, +$/, "");
    },
    formatDate(value){
      return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.getList();
    },
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }
        let param ={
          page:this.currentPage
        }
        //this.paramFilter.page = this.currentPage;

        let response = await BackendRepository.listCampaignCharities(param);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let list =  body.data;
          this.items = list.data;
          this.paginate.total = list.total;
          this.paginate.totalPage = list.last_page;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },

    async destroy(id) {
      if (confirm("Bạn có chắc xóa chiến dịch?")) {
        try {
          this.$bus.$emit("show-loading", true);
          let response = await BackendRepository.deleteCampaignCharities(id);
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.getList(true);
          }
        } catch (e) {
          this.$bus.$emit("show-loading", false);
        }
      }
    },

  },
  watch: {
    $route: "getList",
    currentPage() {
      this.getList();
    },
  },
  created() {
    this.getList();
  }
};
</script>

<style >
.mx-datepicker{
  width: 100%;
}

</style>
